* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #46c0a6 inset;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #D9F5F3;
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #15B5B3; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #15B5B3; 
}